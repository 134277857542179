import React from 'react';
import { Carousel } from 'react-bootstrap';

const Hero = () => {
    return (
        <div id="hero" className="hero-section">
            <Carousel fade controls={false} indicators interval={10000} className="hero-carousel">
                <Carousel.Item className="item-1 active">
                    <div className="item-content container">
                        <div className="item-content-inner">
                            <h2 className="heading">비즈니스에 최적화된<br className="d-none d-md-block" />LLM Training을 자동화 해보세요</h2>
                            <p className="intro">LLM을 자동으로 훈련시켜주고, 맞춤형으로 고도화 시켜줍니다</p>
                            <a className="btn btn-primary btn-cta" href="https://themes.3rdwavemedia.com/bootstrap-templates/startup/appkit-landing-free-bootstrap-theme-for-developers-and-startups/" target="_blank" rel="noopener noreferrer">Download Now</a>
                        </div>
                    </div>
                </Carousel.Item>

                <Carousel.Item className="item-2">
                    <div className="item-content container">
                        <div className="item-content-inner">
                            <h2 className="heading">모두의 도구</h2>
                            <p className="intro">쉽고 빠르게, 무료 웹 도구로 업무 시간을 절약하세요</p>
                            <a className="btn btn-primary btn-cta" href="https://themes.3rdwavemedia.com/bootstrap-templates/startup/appkit-landing-free-bootstrap-theme-for-developers-and-startups/" target="_blank" rel="noopener noreferrer">사이트 이동</a>
                        </div>
                    </div>
                </Carousel.Item>

                <Carousel.Item className="item-3">
                    <div className="item-content container">
                        <div className="item-content-inner">
                            <h2 className="heading">Ready to build outstanding product?</h2>
                            <p className="intro">Get AppKit Landing today and it will help you promote your product effectively!</p>
                            <a className="btn btn-primary btn-cta" href="https://themes.3rdwavemedia.com/bootstrap-templates/startup/appkit-landing-free-bootstrap-theme-for-developers-and-startups/" target="_blank" rel="noopener noreferrer">Get Started</a>
                        </div>
                    </div>
                </Carousel.Item>
            </Carousel>
        </div>
    );
};

export default Hero;
