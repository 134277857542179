import React from 'react';

const Footer = () => {
  return (
    <footer className="footer text-center">
      <div className="container">
        <small className="copyright">
        2024 ©BrewChips All rights reserved <i className="fa-solid fa-heart"></i>
        </small>
      </div>
    </footer>
  );
};

export default Footer;
