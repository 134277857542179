import React from 'react';

const Features = () => {
  return (
    <div id="features" className="features-section">
      <div className="container text-center">
        <h2 className="section-title">Discover Features</h2>
        <p className="intro">
          You can use this section to list your product features. Lorem ipsum dolor sit amet, consectetur adipiscing
          elit. Nam tempus neque sed leo pharetra, rutrum ultrices neque varius.
        </p>
        <div className="tabbed-area row">
          {/* Nav tabs */}
          <div className="feature-nav nav nav-pill flex-column col-lg-4 col-md-6 col-12 order-0 order-md-1" role="tablist" aria-orientation="vertical">
            <a className="nav-link active mb-lg-3" href="#feature-1" aria-controls="feature-1" data-bs-toggle="pill" role="tab" aria-selected="true">
              <i className="fa-solid fa-magic me-2"></i>20+ Use Case-based App Pages
            </a>
            <a className="nav-link mb-lg-3" href="#feature-2" aria-controls="feature-2" data-bs-toggle="pill" role="tab" aria-selected="false">
              <i className="fa-solid fa-cubes me-2"></i>100+ Components and Widgets
            </a>
            <a className="nav-link mb-lg-3" href="#feature-3" aria-controls="feature-3" data-bs-toggle="pill" role="tab" aria-selected="false">
              <i className="fa-solid fa-chart-bar me-2"></i>Useful Chart Libraries
            </a>
            <a className="nav-link mb-lg-3" href="#feature-4" aria-controls="feature-4" data-bs-toggle="pill" role="tab" aria-selected="false">
              <i className="fa-solid fa-code me-2"></i>Valid HTML5 + CSS3
            </a>
          </div>

          {/* Tab panes */}
          <div className="feature-content tab-content col-lg-8 col-md-6 col-12 order-1 order-md-0">
            <div role="tabpanel" className="tab-pane fade show active" id="feature-1">
              <a href="#" target="_blank">
                <img className="img-fluid" src={`${process.env.PUBLIC_URL}/images/feature-1.png`} alt="screenshot" />
              </a>
            </div>
            <div role="tabpanel" className="tab-pane fade" id="feature-2">
              <a href="#" target="_blank">
                <img className="img-fluid" src={`${process.env.PUBLIC_URL}/images/feature-2.png`} alt="screenshot" />
              </a>
            </div>
            {/* Add more feature tabs here */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Features;
