import React, { useEffect } from 'react';
import Gumshoe from 'gumshoejs';

const Header = () => {
  useEffect(() => {
    // 헤더 애니메이션 처리
    const header = document.getElementById('header');
    
    const headerAnimation = () => {
      const scrollTop = window.scrollY;
      if (scrollTop > 0) {
        header.classList.add('header-scrolled');
      } else {
        header.classList.remove('header-scrolled');
      }
    };

    // 페이지 로드 시, 스크롤 시, 리사이즈 시 애니메이션 처리
    window.addEventListener('scroll', headerAnimation);
    window.addEventListener('resize', headerAnimation);
    window.addEventListener('load', headerAnimation);

    // 부드러운 스크롤 처리
    const scrollLinks = document.querySelectorAll('.scrollto');
    const pageNavWrapper = document.getElementById('navbar-collapse');

    scrollLinks.forEach((scrollLink) => {
      scrollLink.addEventListener('click', (e) => {
        e.preventDefault();
        const element = document.querySelector(scrollLink.getAttribute('href'));
        const yOffset = 60; // 헤더 높이만큼 오프셋 적용
        const y = element.getBoundingClientRect().top + window.pageYOffset - yOffset;
        window.scrollTo({ top: y, behavior: 'smooth' });

        // 모바일 메뉴가 열려있으면 클릭 후 닫기
        if (pageNavWrapper.classList.contains('show')) {
          pageNavWrapper.classList.remove('show');
        }
      });
    });

    // Gumshoe 초기화
    const spy = new Gumshoe('#navbar-collapse .nav-link', { offset: 60 });

    // Clean-up 함수: 컴포넌트 언마운트 시 이벤트 리스너와 Gumshoe 제거
    return () => {
      window.removeEventListener('scroll', headerAnimation);
      window.removeEventListener('resize', headerAnimation);
      window.removeEventListener('load', headerAnimation);

      scrollLinks.forEach((scrollLink) => {
        scrollLink.removeEventListener('click', () => {});
      });

      spy.destroy();
    };
  }, []);

  return (
    <header id="header" className="header">
      <nav className="main-nav navbar-expand-md" role="navigation">
        <div className="container-fluid position-relative">
          <a className="logo navbar-brand scrollto" href="#hero">
            <span className="logo-icon-wrapper">
              <img className="logo-icon" src={`${process.env.PUBLIC_URL}/images/logo-icon.svg`} alt="icon" />
            </span>
            <span className="text"><span className="highlight">Brew</span>Chips</span>
          </a>
          <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbar-collapse">
            <span className="icon-bar"></span>
            <span className="icon-bar"></span>
            <span className="icon-bar"></span>
          </button>
          <div id="navbar-collapse" className="navbar-collapse collapse">
            <ul className="nav navbar-nav ms-md-auto">
              <li className="nav-item"><a className="nav-link scrollto" href="#about">About</a></li>
              <li className="nav-item"><a className="nav-link scrollto" href="#testimonials">Testimonials</a></li>
              <li className="nav-item"><a className="nav-link scrollto" href="#features">Features</a></li>
              <li className="nav-item"><a className="nav-link scrollto" href="#team">Team</a></li>
              <li className="nav-item"><a className="nav-link scrollto" href="#contact">Contact</a></li>
            </ul>
          </div>
        </div>
      </nav>
    </header>
  );
};

export default Header;
