import React from 'react';

const Testimonials = () => {
  return (
    <div id="testimonials" className="testimonials-section">
      <div className="container">
        <h2 className="section-title text-center">Many Happy Customers</h2>
        <div className="item mx-auto">
          <div className="profile-holder">
            <img className="profile-image" src={`${process.env.PUBLIC_URL}/images/profile-1.png`} alt="profile" />
          </div>
          <div className="quote-holder">
            <blockquote className="quote">
              <p>
                Testimonial goes here Donec felis odio, sagittis eu cursus ac, porttitor eu purus. In a bibendum dui.
              </p>
              <div className="quote-source">
                <span className="name">@JohnK,</span>
                <span className="meta">San Francisco</span>
              </div>
            </blockquote>
          </div>
        </div>
        {/* Add more testimonial items as needed */}
      </div>
    </div>
  );
};

export default Testimonials;
