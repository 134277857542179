import React from 'react';

const Contact = () => {
  return (
    <div id="contact" className="contact-section">
      <div className="container text-center">
        <h2 className="section-title">Contact Us</h2>
        <p>
        "비즈니스 맞춤형 AI(LLM)모델이나 웹(앱) 도입관련 문의를 주시면 빠르게 답변 드리겠습니다."
        </p>
        <a className="btn btn-cta btn-primary" href="#" target="_blank" rel="noopener noreferrer">
          문의하기
        </a>
      </div>
    </div>
  );
};

export default Contact;
