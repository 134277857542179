import React from 'react';
import Header from './components/Header';
import Hero from './components/Hero';
import About from './components/About';
import Features from './components/Features';
import Testimonials from './components/Testimonials';
import Team from './components/Team';
import Pricing from './components/Pricing';
import Contact from './components/Contact';
import Footer from './components/Footer';

import 'bootstrap/dist/css/bootstrap.min.css'; 
import 'bootstrap/dist/js/bootstrap.bundle.min.js'; 
import './css/styles.css';
import './scss/styles.scss';


function App() {
  return (
    <>
      <Header />
      <Hero />
      <About />
      <Testimonials />
      <Features />
      <Team />
      <Contact />
      <Footer />
    </>
  );
}

export default App;
