import React from 'react';
import { motion } from 'framer-motion';

const About = () => {
  return (
    <div id="about" className="about-section">
      <div className="container text-center">
        <h2 className="section-title">About</h2>
        <motion.div
          initial={{ opacity: 0 }}  // 초기 상태
          animate={{ opacity: 1 }}  // 애니메이션 상태
          transition={{ duration: 5 }}  // 애니메이션 지속 시간
        >
        <p className="intro" style={{ fontSize: '1.25rem', fontWeight: 'bold' }}>
          우리는 모든 사람들이 간편하고 빠른 도움을 받는 양질의 인공지능 기반 웹 툴을 만드는것을 목표로 하고있습니다.<br />
          숙련된 프롬프트 Engineer와 함께 기업 맞춤형 LLM을 시작하세요.<br />
          개인 또는 기업이 LLM을 훈련시키는 방법에 대한 해결책을 제시해줍니다.
        </p>
        </motion.div>
        <ul className="technologies list-inline">
          <li className="list-inline-item">
            <img src={`${process.env.PUBLIC_URL}/images/OpenAI.png`} alt="OpenAI" style={{ width: '148px', height: 'auto' }} />
          </li>
          <li className="list-inline-item">
            <img src={`${process.env.PUBLIC_URL}/images/딥마인드2.jpg`} alt="DeepMind" style={{ width: '148px', height: 'auto' }}/>
          </li>
          <li className="list-inline-item">
            <img src={`${process.env.PUBLIC_URL}/images/MetaAI1.jpg`} alt="MetaAI" style={{ width: '148px', height: 'auto' }}/>
          </li>
          <li className="list-inline-item">
            <img src={`${process.env.PUBLIC_URL}/images/ClaudeAI.png`} alt="Claude" style={{ width: '148px', height: 'auto' }}/>
          </li>
        </ul>
        {/* Add more content here if needed */}
      </div>
    </div>
  );
};

export default About;
